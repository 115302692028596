import React, { useCallback, useMemo, useState } from 'react';

import {
  LineItem,
  Fulfilment,
  ProjectedOrder,
  isFulfilmentStatus,
} from '#types';

import useNavigation from '#hooks/useNavigation';
import useMediaQuery from '#hooks/useMediaQuery';
import useNotifications from '#hooks/useNotifications';
import useCards from '#hooks/useCards';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';
import useNotes from '#hooks/useNotes';

import { settings } from '#materials';
import Flex from '#materials/Flex';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import Split from '#materials/Splt';
import ToggleButton, { ToggleButtonGroup } from '#materials/ToggleButton';

import OrderTotals from './OrderTotals';
import OrderForm, {
  TABLE_KEYS,
  SELECTION_TABLE_KEYS,
  defaultTableKeys,
  defaultSelectionTableKeys,
} from '#components/orders/OrderForm';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderFulfilment;
const localeNotificationKeys = locale.keys.notifications;
const localeButtonKeys = locale.keys.buttons;

const defaultFulfilmentTableKeys = [...defaultTableKeys].filter(
  (key) => key !== TABLE_KEYS.period
    && TABLE_KEYS.unit,
);
const tabletTableKeys = [...defaultFulfilmentTableKeys].filter(
  (key) => key !== TABLE_KEYS.id
    && key !== TABLE_KEYS.productSku
    && key !== TABLE_KEYS.period
    && key !== TABLE_KEYS.requestedQty
    && key !== TABLE_KEYS.unit,
);
const selectionTabletTableKeys = [...defaultSelectionTableKeys].filter(
  (key) => key !== SELECTION_TABLE_KEYS.id
    && key !== SELECTION_TABLE_KEYS.unit
);

interface OrderFulfilmentProps {
  order : ProjectedOrder;
}

const OrderFulfilment = ({ order } : OrderFulfilmentProps) => {
  const { navigate } = useNavigation();
  const { isDesktop } = useMediaQuery();
  const { createNotification } = useNotifications();
  const { getCustomerCreditCards } = useCards();
  const {
    refreshOrders,
    refreshLineItems,
    deleteFulfilment,
    updateFulfilment,
    bulkUpdateFulfilments,
    payOrder,
    createFulfilment,
    generateDefaultFulfilment,
  } = useOrders()
  const {
    refreshSelections,
    getLineItemFulfilment,
    getSelectionFulfilment,
  } = useOptions()
  const {
    refreshSubscriptions,
    generateOrder,
  } = useSubscriptions()
  const { refreshNotes } = useNotes();

  const [confirmCancelOrder, setConfirmCancelOrder] = useState(false);
  const [confirmProcess, setConfirmProcess] = useState(false);
  const [confirmMarkPaid, setConfirmMarkPaid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [generating, setGenerating] = useState(false)

  const cardOnFile = useMemo(() => (
    !!order.customer
      && getCustomerCreditCards(order.customer).filter(
        cc => cc.expYear > new Date().getFullYear()
          || (cc.expYear === new Date().getFullYear()
            && cc.expMonth > new Date().getMonth()),
      ).length > 0
  ), [order, getCustomerCreditCards]);

  const customerIntegrated = useMemo(() => (
    order.customer?.integrations
      && listRecords(order.customer.integrations).length
  ), [order]);
  const orderIntegrated = useMemo(() => (
    order.order?.integrations
      && listRecords(order.order.integrations).length
  ), [order]);

  const generated = useMemo(() => (
    order?.order
      && Object.values(order.lineItems)
        .every((lineItem) => getLineItemFulfilment(lineItem, order))
      && Object.values(order.selections)
        .every((selection) => getSelectionFulfilment(selection, order))
  ), [order, getLineItemFulfilment, getSelectionFulfilment]);

  const handleRefresh = useCallback(async () => {
    setGenerating(true);
    await Promise.all([
      refreshLineItems(),
      refreshOrders(),
      refreshSelections(),
      refreshSubscriptions(),
      refreshNotes(),
    ]);
    setGenerating(false);
  }, [
    refreshLineItems,
    refreshOrders,
    refreshSelections,
    refreshSubscriptions,
    refreshNotes,
  ]);

  const handleInitProcess = useCallback(async () => {
    if (!order?.order) return;
    setConfirmProcess(true);
  }, [order]);

  const handleCancelProcess = useCallback(async () => {
    setConfirmProcess(false);
  }, []);

  const handleProcess = useCallback(async (
    outOfSystem : boolean = false,
  ) => {
    setProcessing(true);
    const hasItems = Object.values(order.lineItems).length
      || Object.values(order.selections).length
      || Object.values(order.subscriptions).length;
    const targetOrder = hasItems
      ? listRecords(await generateOrder(order, {
        defer : false,
        failFast : true,
        resolveSelections : order.status === 'pending',
      }))[0]
      : order.order;
    if (
      !targetOrder
        || (order.order?.id && (targetOrder.id !== order.order.id))
    ) {
      createNotification({
        key : 'create-fulfilments-error',
        message : localize(localeNotificationKeys.fulfilments.generate.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
      setProcessing(false);
      return;
    }

    if (!targetOrder) return;
    setProcessing(true);
    const response = await payOrder(targetOrder, { outOfSystem });
    const newOrder = response?.order ?? null;
    const error = response?.error ?? 'requestError' as const;
    if (newOrder) {
      createNotification({
        key : 'process-order-success',
        message : localize(localeNotificationKeys.orders.process.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      if ([
        'badCardNumber',
        'badCardPostal',
        'badCardCVV',
      ].includes(error)) {
        createNotification({
          key : 'process-order-card-error',
          message : localize(localeNotificationKeys.orders.process.cardError),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      } else if (error === 'badCardExpiry') {
        createNotification({
          key : 'process-order-postal-error',
          message : localize(localeNotificationKeys.orders.process.cardExpired),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      } else if (error === 'insufficientFunds') {
        createNotification({
          key : 'process-order-insufficient',
          message :
            localize(localeNotificationKeys.orders.process.insufficient),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.secondary,
        });
      } else {
        createNotification({
          key : 'process-order-error',
          message : localize(localeNotificationKeys.orders.process.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      }
    }
    setProcessing(false);
    setConfirmProcess(false);
  }, [order, generateOrder, payOrder, createNotification]);

  const handleConfirmProcess = useCallback(async () => {
    if (!order?.order) return;
    handleProcess();
  }, [order, handleProcess]);

  const handleInitMarkPaid = useCallback(async () => {
    if (!order?.order) return;
    setConfirmMarkPaid(true);
  }, [order]);

  const handleCancelMarkPaid = useCallback(async () => {
    setConfirmMarkPaid(false);
  }, []);

  const handleConfirmMarkPaid = useCallback(async () => {
    if (!order?.order) return;
    handleProcess(true);
    setConfirmMarkPaid(false);
  }, [order, handleProcess]);

  const handleCreateFulfilment = useCallback(
    async (lineItem : LineItem) => {
      const fulfilment = generateDefaultFulfilment({
        order,
        product : lineItem.productId,
      })
      if (!fulfilment) return;
      fulfilment.status = 'confirmed';
      fulfilment.fulfilledProductId = lineItem.productId;
      fulfilment.requestedQty = 0;
      fulfilment.fulfilledQty = lineItem.quantity;
      const newOrphanedFulfilment = await createFulfilment(fulfilment);

      if(newOrphanedFulfilment) {
        createNotification({
          key : 'create-fulfilment-success',
          message : localize(
            localeNotificationKeys.fulfilments.createOrphanedFulfilment.success
          ),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'create-fulfilment-error',
          message : localize(
            localeNotificationKeys.fulfilments.createOrphanedFulfilment.error
          ),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      }
  }, [order, createFulfilment, generateDefaultFulfilment, createNotification]);

  const handleUpdateFulfilment = useCallback(async (
    fulfilment : Fulfilment
  ) => {
    const newFulfilment = fulfilment.id
      ? await updateFulfilment(fulfilment)
      : await createFulfilment(fulfilment);
    if (newFulfilment) {
      createNotification({
        key : 'update-fulfilment-success',
        message : localize(localeNotificationKeys.fulfilments.update.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-fulfilment-error',
        message : localize(localeNotificationKeys.fulfilments.update.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [createFulfilment, updateFulfilment, createNotification])

  const handleDeleteFulfilment = useCallback((
    fulfilment : Fulfilment
  ) => async () => {
    const newFulfilment = await deleteFulfilment(fulfilment);
    if (newFulfilment) {
      createNotification({
        key : 'delete-fulfilment-success',
        message : localize(localeNotificationKeys.fulfilments.delete.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-fulfilment-error',
        message : localize(localeNotificationKeys.fulfilments.delete.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [deleteFulfilment, createNotification]);

  const handleGenerate = useCallback(async () => {
    setGenerating(true);
    const success = !!(await generateOrder(
      order,
      { resolveSelections : true },
    ));
    setGenerating(false);

    if (success) {
      createNotification({
        key : 'create-fulfilments-success',
        message : localize(localeNotificationKeys.fulfilments.generate.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'create-fulfilments-error',
        message : localize(localeNotificationKeys.fulfilments.generate.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, generateOrder, createNotification]);

  const handleStatusChange = useCallback(async (status : string) => {
    if (!order?.order || !isFulfilmentStatus(status)) return;
    const fulfilments = Object.values(order.order.fulfilments);
    if (!fulfilments.length) return;

    const generated = !!(await generateOrder(
      order,
      { resolveSelections : order.status === 'pending' },
    ));
    if (!generated) {
      createNotification({
        key : 'update-fulfilments-error',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.error,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }

    const updates = await bulkUpdateFulfilments(
      fulfilments.map((fulfilment) => ({
        ...fulfilment,
        status,
      })
    ));
    if (updates) {
      createNotification({
        key : 'update-fulfilments-success',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.success,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-fulfilments-error',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.error,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, bulkUpdateFulfilments, generateOrder, createNotification]);

  const handleInitCancelOrder = useCallback(async () => {
    setConfirmCancelOrder(true);
  }, []);

  const handleCancelCancelOrder = useCallback(async () => {
    setConfirmCancelOrder(false);
  }, []);

  const handleConfirmCancelOrder = useCallback(async () => {
    handleStatusChange('cancelled')
    setConfirmCancelOrder(false);
  }, [handleStatusChange]);

  const inProgress = [
    'inProgress',
    'ready',
    'fulfilled',
    'cancelled',
  ].includes(order.status ?? 'pending');
  const cancelled = order.status === 'cancelled';

  return (
    <>
      { (!order.customer) && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.noCustomer)
            + ` ${order.guestCode}` }
        </Banner>
      ) }
      { (!order.paid && !cardOnFile && order.customer) && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.noCardOnFile) }
        </Banner>
      ) }
      { (order.customer && !customerIntegrated) && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          colour={settings.colours.alert.alert}
          actions={(
            <Button
              onClick={() => navigate(`/customers/${order.customer?.id}`)}
            >
              { localize(localeButtonKeys.view) }
            </Button>
          )}
        >
          { localize(localeContentKeys.noCustomerIntegrations) }
        </Banner>
      ) }
      { (order.order && !orderIntegrated) && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.noOrderIntegrations) }
        </Banner>
      ) }
      { confirmCancelOrder && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <>
              <Button onClick={handleConfirmCancelOrder}>
                { localize(localeButtonKeys.confirm) }
              </Button>
              <Button onClick={handleCancelCancelOrder}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmCancelOrder) }
        </Banner>
      ) }
      { confirmProcess && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <>
              <Button onClick={handleConfirmProcess}>
                { localize(localeButtonKeys.process) }
              </Button>
              <Button onClick={handleCancelProcess}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.secondary}
        >
          { localize(localeContentKeys.confirmProcess) }
        </Banner>
      ) }
      { confirmMarkPaid && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <>
              <Button onClick={handleConfirmMarkPaid}>
                { localize(localeButtonKeys.confirm) }
              </Button>
              <Button onClick={handleCancelMarkPaid}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.secondary}
        >
          { localize(localeContentKeys.confirmMarkPaid) }
        </Banner>
      ) }
      <Split
        left={(
          <Button
            onClick={handleRefresh}
            disabled={generating || processing}
          >
            Refresh
          </Button>
        )}
        right={(
          <Flex
            direction={
              isDesktop
                ? settings.directions.row
                : settings.directions.column
            }
          >
            <Button
              disabled={
                confirmProcess
                  || confirmMarkPaid
                  || confirmCancelOrder
                  || cancelled
                  || !cardOnFile
                  || !customerIntegrated
                  || !orderIntegrated
                  || generating
                  || processing
                  || order?.paid
                  || (order.totals
                    .find(t => t.key === 'total')?.total.amount ?? 0) <= 0
              }
              onClick={handleInitProcess}
            >
              {
                order.paid
                  ? localize(localeContentKeys.paid)
                  : localize(localeContentKeys.processPayment)
              }
            </Button>
            { (!order.paid && !cancelled) && (
              <Button
                disabled={
                  confirmProcess
                    || confirmMarkPaid
                    || confirmCancelOrder
                    || generating
                    || processing
                    || order?.paid
                    || !generated
                }
                onClick={handleInitMarkPaid}
              >
                { localize(localeContentKeys.markPaid) }
              </Button>
            ) }
            { !order.paid && (
              <Button
                disabled={
                  cancelled
                    || confirmProcess
                    || confirmMarkPaid
                    || confirmCancelOrder
                    || generating
                    || processing
                    || order?.paid
                    || !generated
                }
                onClick={handleInitCancelOrder}
                colour={settings.colours.button.alert}
              >
                { cancelled
                  ? localize(localeContentKeys.canceled)
                  : localize(localeButtonKeys.cancel)
                }
              </Button>
            ) }
            { (generated || order?.paid)
              ? (!cancelled && (
                <ToggleButtonGroup
                  disabled={
                    confirmProcess
                      || confirmMarkPaid
                      || confirmCancelOrder
                      || generating
                      || processing
                  }
                >
                  <ToggleButton
                    value="inProgress"
                    selected={order?.status === 'inProgress'}
                    onSelect={handleStatusChange}
                    first
                  >
                    In Progress
                  </ToggleButton>
                  <ToggleButton
                    value="ready"
                    selected={order?.status === 'ready'}
                    onSelect={handleStatusChange}
                  >
                    Ready
                  </ToggleButton>
                  <ToggleButton
                    value="fulfilled"
                    selected={order?.status === 'fulfilled'}
                    onSelect={handleStatusChange}
                    last
                  >
                    Complete
                  </ToggleButton>
                </ToggleButtonGroup>)
              ) : (
                <Button
                  onClick={handleGenerate}
                >
                  Generate
                </Button>
              )
            }
          </Flex>
        )}
      />
      <OrderForm
        order={order}
        mode='fulfilment'
        disabled={order.paid || inProgress}
        onAddLineItem={handleCreateFulfilment}
        onUpdateFulfilment={handleUpdateFulfilment}
        onDeleteFulfilment={handleDeleteFulfilment}
        tableKeys={isDesktop ? defaultFulfilmentTableKeys : tabletTableKeys}
        selectionTableKeys={
          isDesktop
            ? defaultSelectionTableKeys
            : selectionTabletTableKeys
        }
      />
      <OrderTotals
        order={order}
        mode="fulfilment"
        disabled={order.paid || inProgress}
      />
    </>
  )
}

export default OrderFulfilment;
